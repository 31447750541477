/* eslint import/no-cycle: 0 */
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import React, { useEffect, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import Caption from 'cms/components/caption/caption';
import ShoppableHotspot from 'cms/components/ShoppableHotspot/ShoppableHotspot';
import { shouldLoad } from 'universal/http-client';
import { openModal } from 'client/common/components/Modal/actions';
import window from 'window-or-global';
import { getPercentage, getScaleNumber, getTranslateYNumber } from 'cms/components/image/utils/image-utils';
import ShoppableLookHotspot from 'cms/components/ShoppableLook/ShoppableLook';
import { bindActionCreators } from 'redux';
import { referralSourceClicked } from 'shared/actions/actions-page';
import {
  getModalContent,
  setActiveEntryId,
  setDynamicRailLinkHovered,
  setDynamicRailLinkNotHovered,
} from '../../actions/index';
import {
  checkForICIDAndAddTags,
  concatTrackTagsObjects,
  getCaptionStyleProps,
  getTrackingTags,
  optimizeImageUrl,
} from '../utils';
import { calculateActualDimensions } from './dimensionCalculator';
import BadgeAsset from '../badge/badgesAsset';
import './imageAsset.scss';

const CAPTION_MARGIN = '20';

export function DumbImageAsset(props) {
  const {
    dispatch,
    cmsContentItem = {},
    isMobilePhone,
    isDesktop,
    lazyloadCmsContent,
    trackTags: propsTrackTags = {},
    neverLazyload,
    openModal,
    getModalContent,
    setActiveEntryId,
    isDynamicRailHovered,
    setDynamicRailLinkHovered,
    setDynamicRailLinkNotHovered,
    imageOptimizationToggle,
    cssrgbToggle,
  } = props;

  if (!cmsContentItem?.fields) {
    return null;
  }
  const {
    linkTo: link,
    openLinkInNewTab,
    altText,
    name,
    desktopImage: [desktopImage] = [],
    mobileImage: [mobileImage] = [],
    desktopImage: [{ secure_url: desktopImageSrc } = {}] = [],
    mobileImage: [{ secure_url: mobileImageSrc } = {}] = [],
    desktopHoverImage: [{ secure_url: desktopHoverImageSrc } = {}] = [],
    caption,
    captionBadge: [{ url: captionBadgeSrc } = {}] = [],
    credit,
    desktopImageMaps,
    mobileImageMaps,
    desktopCaptionColor,
    desktopCaptionPosition,
    mobileCaptionColor,
    mobileCaptionPosition,
    badges,
    isLazyLoaded = true,
    imageBorderColor,
    imageBorderWidth,
    trackingTags: imageTrackTags = [],
    zoomOnHover,
    zoomPercentage = 30,
    hoverColorOverlay,
    fullWidthModal,
    transparentAspectRatio,
    mobileAspectRatio,
    parallaxShiftZoomBehavior = false,
    shoppableProducts = [],
    ymalProducts = [],
  } = get(cmsContentItem, 'fields', {});

  if (isEmpty(desktopImageSrc)) {
    return null;
  }

  const isProductRail = name?.includes('Homepage Products');
  const cdnHosts = global.cloudinary?.cdnHosts || {};
  const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);
  const linkTo = link?.length
    && checkForICIDAndAddTags(link, propsTrackTags, imageTrackTags, isModalContent);
  const isBordered = !!imageBorderColor && !!imageBorderWidth;
  const imageBorderStyles = {
    border: `${imageBorderWidth}px solid ${imageBorderColor}`,
  };
  const paxWrapperRef = useRef();
  const paxImageRef = useRef();
  const desktopTabletShiftPercentage = 60;
  const mobileShiftPercentage = 85;
  const zoomCorrectionPercentage = 12.5;
  let paxImageHeight;

  useEffect(() => {
    const scrollHandler = () => {
      if (paxImageRef.current && paxWrapperRef.current) {
        if (!paxImageHeight) paxImageHeight = paxImageRef.current.getBoundingClientRect().height;
        const { top, bottom, height } = paxWrapperRef.current.getBoundingClientRect();
        if (top < 0 && bottom > 0) {
          // eslint-disable-next-line max-len
          const imgPercentageToHide = 100 - (isMobilePhone ? mobileShiftPercentage : desktopTabletShiftPercentage);
          const imgMaxShiftVal = (paxImageHeight / 100)
            * (imgPercentageToHide + zoomCorrectionPercentage);
          const scrolledPercentage = getPercentage(top, height);
          const scaleNumber = getScaleNumber(scrolledPercentage);
          const translateYNumber = getTranslateYNumber(scrolledPercentage, imgMaxShiftVal);
          paxImageRef.current.style.cssText = `transform: translate3d(0px, -${translateYNumber}px, 0px) scale(1.${scaleNumber})`;
        }
      }
    };

    if (parallaxShiftZoomBehavior) {
      window.addEventListener('scroll', scrollHandler);
      window.dispatchEvent(new Event('scroll'));
    }

    return () => {
      if (parallaxShiftZoomBehavior) {
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, []);

  const renderTransparentImage = (imageSrc) => {
    const widthHeightValues = transparentAspectRatio.split('x');
    const desktopAspectRatioPercentage = widthHeightValues
      && (widthHeightValues[1] / widthHeightValues[0] * 100);
    const mobileWidthHeightValues = mobileAspectRatio?.split('x');
    const mobileAspectRatioPercentage = mobileWidthHeightValues
      && (mobileWidthHeightValues[1] / mobileWidthHeightValues[0] * 100);
    return (
      <div
        className="image-asset-wrapper background"
        style={{
          backgroundImage: `url(${imageSrc})`,
          paddingTop: isMobilePhone && mobileAspectRatio ? `${mobileAspectRatioPercentage}%` : `${desktopAspectRatioPercentage}%`,
        }}
      />
    );
  };

  const getAltText = () => {
    const altDesktopImageClaudinary = get(cmsContentItem, 'fields.desktopImage[0].context.custom.alt');
    const altMobileImageClaudinary = get(cmsContentItem, 'fields.mobileImage[0].context.custom.alt');

    let attributeValue = altDesktopImageClaudinary;

    if (isMobilePhone) {
      attributeValue = mobileImage ? altMobileImageClaudinary : altDesktopImageClaudinary;
    }
    return attributeValue || altText || name;
  };

  const imageAttributesValue = getAltText();

  const onClickHandler = (e, link, fullWidthModal = false) => {
    if (!openLinkInNewTab && !isUndefined(openLinkInNewTab)) {
      e.preventDefault();

      const { modalContentApi } = props;
      const entryId = link;

      if (entryId && shouldLoad(modalContentApi)) {
        getModalContent(entryId);
      }

      setActiveEntryId(entryId);

      openModal({
        type: 'InfoModal',
        fullWidth: fullWidthModal,
      });
    }
    if (isProductRail && !linkTo.includes('/editorial')) {
      referralSourceClicked('HP_Designer_Arrivals', 'product');
    }
  };

  const renderBadge = (badges) => {
    return badges.map((badge, index) => <BadgeAsset cmsContentItem={badge} key={index} />);
  };

  const onMouseOverLink = () => {
    if (isDynamicRailHovered) {
      setDynamicRailLinkHovered();
    }
  };

  const onMouseLeaveLink = () => {
    if (isDynamicRailHovered) {
      setDynamicRailLinkNotHovered();
    }
  };

  const renderImageMaps = (ImageMaps) => {
    const combinedTrackTagsFromImg = concatTrackTagsObjects(
      propsTrackTags,
      getTrackingTags(imageTrackTags),
    );
    return ImageMaps.map((item) => {
      const {
        trackingTags: itemTrackTags,
        url,
        top,
        left,
        height,
        width,
      } = item?.fields;
      const isShoppableHotspot = url.startsWith('prod');
      if (isShoppableHotspot) {
        return <ShoppableHotspot url={url} top={top} left={left} />;
      }
      const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);
      const imageMapItemLink = checkForICIDAndAddTags(
        url,
        combinedTrackTagsFromImg,
        itemTrackTags,
        isModalContent,
      );
      return (
        <a
          style={{
            position: 'absolute',
            top: `${top}%`,
            left: `${left}%`,
            height: `${height}%`,
            width: `${width}%`,
          }}
          href={imageMapItemLink}
          target={item.fields?.openLinkInNewTab ? '_blank' : '_self'}
          rel="noreferrer noopener"
          className="image-link"
          draggable={false}
          aria-label={imageAttributesValue}
          onDragStart={(e) => e.preventDefault()}
          onClick={(e) => onClickHandler(e, url, fullWidthModal)}
          onMouseOver={onMouseOverLink}
          onMouseLeave={onMouseLeaveLink}
        >
          <img
            style={{ height: `${item.fields.height}%`, width: '100%' }}
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            alt=""
            title=""
          />
        </a>
      );
    });
  };

  const wrapWithLink = (elem, href, openLinkInNewTab) => {
    return (href ? (
      <a
        href={href}
        target={openLinkInNewTab ? '_blank' : '_self'}
        rel="noreferrer noopener"
        onClick={(e) => onClickHandler(e, href, fullWidthModal)}
        className="image-link"
        onMouseOver={onMouseOverLink}
        aria-label={imageAttributesValue}
        onMouseLeave={onMouseLeaveLink}
      >
        {elem}
      </a>
    ) : elem);
  };

  let imageSrc = isMobilePhone && mobileImageSrc ? mobileImageSrc : desktopImageSrc;
  const actualImageDimensions = calculateActualDimensions(
    (isMobilePhone && mobileImage) ? mobileImage : desktopImage
  );

  if (cssrgbToggle) {
    imageSrc = imageSrc.replace('q_auto', 'cs_srgb,q_auto');
  }

  if (imageOptimizationToggle && isMobilePhone) {
    imageSrc = optimizeImageUrl(imageSrc);
  }

  if (transparentAspectRatio && imageSrc) {
    return (
      renderTransparentImage(imageSrc)
    );
  }

  const cdnImageSrc = Object.entries(cdnHosts)
    .sort(([host1], [host2]) => host2.length - host1.length)
    .reduce((url, [cloudinaryHost, nmHost]) => url.replace(cloudinaryHost, nmHost), imageSrc);
  // eslint-disable-next-line max-len
  let aspectRatio = actualImageDimensions && (actualImageDimensions.height / actualImageDimensions.width * 100);

  if (parallaxShiftZoomBehavior) {
    const targetPercentage = isMobilePhone ? mobileShiftPercentage : desktopTabletShiftPercentage;
    // eslint-disable-next-line max-len
    aspectRatio = actualImageDimensions && ((actualImageDimensions.height / 100 * targetPercentage) / actualImageDimensions.width * 100);
  }

  const ImageMaps = (isMobilePhone && mobileImage) ? mobileImageMaps : desktopImageMaps;

  const renderCaption = (position, color, isMobile = false) => {
    if (position && color) {
      let style = getCaptionStyleProps(position, color, CAPTION_MARGIN, CAPTION_MARGIN, true);
      const isShopTheLook = shoppableProducts.length > 0 || ymalProducts.length > 0;

      if (isShopTheLook && isDesktop && position === 'Bottom Right') {
        style = {
          ...style,
          maxWidth: 'calc(100% - 195px)',
        };
      }

      return (
        <Caption
          style={style}
          badgeSrc={captionBadgeSrc}
          caption={caption}
          credit={credit}
          isMobile={isMobile}
        />
      );
    }

    return null;
  };

  const hoverColorOverlayEl = hoverColorOverlay ? (
    <div
      className="overlay-color-element"
      style={{ backgroundColor: hoverColorOverlay }}
    />
  ) : null;

  let desktopHoverImageUrl = desktopHoverImageSrc;
  if (cssrgbToggle && desktopHoverImageSrc) {
    desktopHoverImageUrl = desktopHoverImageUrl.replace('q_auto', 'cs_srgb,q_auto');
  }
  const desktopHoverImageEl = desktopHoverImageSrc ? (
    <img
      className="hover-image"
      src={desktopHoverImageUrl}
      alt=""
    />
  ) : null;

  const doRenderHoverImgOverlay = !isMobilePhone || (isMobilePhone && !mobileImageSrc);
  const renderImage = (imageClassName) => (
    <>
      {doRenderHoverImgOverlay && desktopHoverImageEl}
      <img
        src={cdnImageSrc}
        alt={imageAttributesValue}
        title={imageAttributesValue}
        className={imageClassName}
        ref={paxImageRef}
        loading={isLazyLoaded ? 'lazy' : 'eager'}
      />
      {doRenderHoverImgOverlay && hoverColorOverlayEl}
      {(shoppableProducts.length > 0 || ymalProducts.length > 0) && (
        // eslint-disable-next-line max-len
        <ShoppableLookHotspot dispatch={dispatch} ids={shoppableProducts} ymalIds={ymalProducts} isDesktop={isDesktop} />
      )}
      {ImageMaps !== undefined ? (
        <div className="image_maps_block">{renderImageMaps(ImageMaps)}</div>
      ) : null}
      {renderCaption(desktopCaptionPosition, desktopCaptionColor)}
      {renderCaption(
        mobileCaptionPosition || desktopCaptionPosition,
        mobileCaptionColor || desktopCaptionColor,
        true
      )}
      {badges && renderBadge(badges)}
    </>
  );

  const wrapperConfig = {
    className: classNames('image-asset-wrapper', {
      'overflow-hidden': parallaxShiftZoomBehavior,
      'zoom-on-hover': zoomOnHover && isDesktop,
      [`zoom-on-hover-${zoomPercentage}`]: zoomOnHover && isDesktop,
      [`zoom-${zoomPercentage}`]: zoomOnHover && !isDesktop,
      'overlay-on-hover': hoverColorOverlay && isDesktop,
      'show-overlay': hoverColorOverlay && !isDesktop,
      'change-image-on-hover':
        (!isMobilePhone && desktopHoverImageSrc)
        || (isMobilePhone && desktopHoverImageSrc && !mobileImageSrc),
    }),
    style: isBordered ? imageBorderStyles : null,
  };

  if (lazyloadCmsContent && aspectRatio && !neverLazyload) {
    if (isLazyLoaded) {
      return (
        <InView
          rootMargin="100px"
          triggerOnce
        >
          {
            ({ inView, ref }) => {
              const shouldRenderImage = !lazyloadCmsContent || inView;
              const overlayClasses = classNames('image-asset-overlay', shouldRenderImage && 'image-asset-overlay-fade-out');
              const toRender = (
                <div ref={ref} {...wrapperConfig}>
                  {shouldRenderImage && renderImage('image-asset-lazyload')}
                  {wrapWithLink(
                    <div
                      className={overlayClasses}
                      style={{ paddingTop: `${aspectRatio}%` }}
                    />,
                    linkTo,
                    openLinkInNewTab
                  )}
                </div>
              );
              // eslint-disable-next-line max-len
              return parallaxShiftZoomBehavior ? <div ref={paxWrapperRef}>{toRender}</div> : toRender;
            }
          }
        </InView>
      );
    }
    const overlayClasses = classNames('image-asset-overlay');
    return (
      <div ref={paxWrapperRef} {...wrapperConfig}>
        {renderImage('image-asset-lazyload')}
        {wrapWithLink(<div className={overlayClasses} style={{ paddingTop: `${aspectRatio}%`, opacity: 0 }} />, linkTo, openLinkInNewTab)}
      </div>
    );
  }
  if (linkTo) {
    return (
      <div ref={paxWrapperRef} {...wrapperConfig}>
        <a
          href={linkTo}
          target={openLinkInNewTab ? '_blank' : '_self'}
          rel="noreferrer noopener"
          onClick={(e) => onClickHandler(e, linkTo, fullWidthModal)}
          className="image-link"
          onMouseOver={onMouseOverLink}
          aria-label={imageAttributesValue}
          onMouseLeave={onMouseLeaveLink}
        >
          {renderImage('image-asset')}
        </a>
      </div>
    );
  }
  return (
    <div ref={paxWrapperRef} {...wrapperConfig}>
      {renderImage('image-asset')}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  const actionsToBind = {
    openModal,
    getModalContent,
    setActiveEntryId,
    setDynamicRailLinkHovered,
    setDynamicRailLinkNotHovered,
  };
  const boundActions = bindActionCreators(actionsToBind, dispatch);

  return {
    ...boundActions,
    dispatch,
  };
};

const mapStateToProps = (state, ownProps) => {
  const contentId = ownProps.cmsContentItem?.sys?.id;

  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isDesktop: get(state, 'device.isDesktop'),
    lazyloadCmsContent: state.toggles.LAZYLOAD_CMS_CONTENT,
    modalContentApi: state.api[`modal_content_${contentId}`.toLowerCase()],
    isDynamicRailHovered: get(state, 'cms.dynamicRail.hovered'),
    imageOptimizationToggle: state.toggles.IMAGE_OPTIMIZATIONS,
    cssrgbToggle: get(state, 'toggles.CS_SRGB_EDITORIAL', false),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DumbImageAsset);
