import React, { useState, useLayoutEffect } from 'react';
import cx from 'classnames';
import './MobileNav.scss';

export const pageOptions = [
  {
    value: 'myStylePreferences',
    title: 'My Style Preferences',
    href: '/my/preferences/stylepreferences',
  },
  { value: 'favoriteItems', title: 'Favorite Items', href: '/favoriteitems' },
  {
    value: 'myStore',
    title: 'My Store',
    href: '/my/favorites/store',
  },
  {
    value: 'accountOverview',
    title: 'Account Overview',
    href: '/my/account/overview',
  },
  { value: 'orderHistory', title: 'Order History', href: '/order-history' },
  {
    value: 'addressBook',
    title: 'Address Book',
    href: '/my/account/address',
  },
  {
    value: 'paymentInformation',
    title: 'Payment Information',
    href: '/my/account/payment',
  },
  { value: 'specialOffers', title: 'Special Offers', href: '/specialoffers' },
];

function MobileNav({ pageType, toggles = {} }) {
  const { UNIFIED_PREFERENCES, NMO_LOYALTY_NEW } = toggles;
  const [isDropDownOpen, setDropDownOpen] = useState(false);

  const setCommPref = () => {
    if (NMO_LOYALTY_NEW) {
      pageOptions.splice(4, 0, {
        value: 'myNMCreditCard',
        title: 'NM Credit Card',
        href: '/my/NMCreditCard',
      });
    }
    if (UNIFIED_PREFERENCES) {
      pageOptions.splice(5, 0, {
        value: 'communicationPreferences',
        title: 'Communication Preferences',
        href: '/my/preferences/communication',
      });
    }
  };

  useLayoutEffect(() => {
    setCommPref();
  }, []);
  const onMobileNavOpen = (e) => {
    e.preventDefault();
    setDropDownOpen(!isDropDownOpen);
  };

  const onClick = (option) => window.location.href = option.href;//eslint-disable-line

  const defaultValue = pageOptions.find(({ value }) => value === pageType);

  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100">
      <div id="dropdownWrapper">
        <button
          id={cx(isDropDownOpen ? 'selectDropdownOpen' : 'selectDropdown')}
          aria-label="Account pages Navigation Menu"
          aria-expanded={isDropDownOpen}
          aria-haspopup
          onClick={(e) => onMobileNavOpen(e)}
        >
          {defaultValue?.title}
        </button>
        {isDropDownOpen && (
          <div id="selectOptions">
            {pageOptions.map((option) => (
              <button
                className={cx(
                  option.value === pageType
                    ? 'selectOptionCurrentPage'
                    : 'selectOption'
                )}
                aria-label={option.title}
                key={option.value}
                onClick={() => onClick(option)}
              >
                {option.title}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}


export default MobileNav;
